<template>
    <div>
        <van-nav-bar title="添加评审专业" left-arrow @click-left="$router.push('/' + $route.params.fromRouter)"/>
        <div class="flex-around whiteBg mar-t2">
            <h3 :class="{f5:true,title : true,'active-title': activeTitle ==0}" @click="activeTitle = 0">工程类</h3>
            <h3 :class="{f5:true,title : true,'active-title': activeTitle ==1}" @click="activeTitle = 1">货物类</h3>
            <h3 :class="{f5:true,title : true,'active-title': activeTitle ==2}" @click="activeTitle = 2">服务类</h3>
        </div>
        <div class="w-line mar-t2"></div>
        <div class="pad-row">
            <ul>
                <li v-for="(item,index) in list" :key="index" >
                    <div class="flex-item" @click="item.click = !item.click">
                        <van-icon
                            :color="item.checked ? '#00CCB2' : '#D9D9D9'"
                            :name="item.checked ? 'checked':'circle'"
                            @click.stop="selectedParent(item)"
                            size="0.5rem"/>
                        <h3 class="mar-l2">{{ item.name }}</h3>
                        <van-icon name="play" :class="{arrow:true,'arrow-up':item.click}" />
                    </div>
                    <ul v-show="item.click">
                        <li v-for="(child,i) in item.children" :key="child.id" class="flex-item son" @click="selectedSon(item,child)">
                            <van-icon
                            :color="child.checked ? '#00CCB2' : '#D9D9D9'"
                            :name="child.checked ? 'checked':'circle'"
                            size="0.5rem"/>
                            <h3 class="mar-l2">{{ child.majorName }}</h3>
                        </li>
                    </ul>
                </li>
            </ul>
          <div class="h2"></div>
        </div>

        <div class="fixed-bottom whiteBg pad-row" style="padding-bottom:0.3rem">
          <button class="btn w-100"  @click="doSave">保存</button>
        </div>
    </div>
</template>

<script>
import majorList from './../../test/reviewList'
import {Search,Icon} from 'vant'
export default {
    data(){
        return{
            searchV : '',
            activeTitle : 0,
            list : [],
            queryParam: {
                orgId: "",
                expertId:''
            },
            selectedMajor:[],
            fromRouter : ''
        }
    },

    components:{
        [Icon.name]:Icon,
        [Search.name]:Search
    },

    beforeMount(){
        this.fromRouter = this.$route.params.fromRouter || ''
        if(this.fromRouter == '') {
            this.$router.push('/demo-1')
        }else {
            this.list = majorList.gongchengList
            this.onReset()
        }
    },

    watch:{
        activeTitle(newV){
            if(newV == 0 ) this.list = majorList.gongchengList;
            else if(newV ==1) this.list = majorList.huowuList;
            else if(newV ==2) this.list = majorList.fuwuList;
        }
    },

    methods: {

        /**页面初始化： 还原选中的专业 */
        onReset(){
            this.selectedMajor = this.$ls.get('major-demo-list') || []
            console.log(this.selectedMajor);
            if(this.selectedMajor.length == 0){
                majorList.gongchengList.forEach( item => {
                    if(item.checked){
                        item.children.forEach( child =>{
                            child.checked = false
                        })
                        item.checked = false
                    }
                })

                majorList.huowuList.forEach( item => {
                    if(item.checked){
                        item.children.forEach( child =>{
                            child.checked = false
                        })
                        item.checked = false
                    }
                })

                majorList.fuwuList.forEach( item => {
                    if(item.checked){
                        item.children.forEach( child =>{
                            child.checked = false
                        })
                        item.checked = false
                    }
                })
            }else {
            for (let j = 0; j < this.selectedMajor.length; j++) {
                const temp = this.selectedMajor[j];
                let getResult = false
                for (let i = 0; i < majorList.gongchengList.length; i++) {
                    if(temp.parentId == majorList.gongchengList[i].key){
                        const tt = majorList.gongchengList[i]
                        for (let m = 0; m < tt.children.length; m++) {
                            const third = tt.children[m]
                            if(temp.id == third.id) {
                                majorList.gongchengList[i].checked= true
                                third.checked = true
                                getResult = true
                                break
                            }
                            else continue
                        }
                        if(getResult) break
                    }
                }
                if(getResult) continue

                for (let i = 0; i < majorList.huowuList.length; i++) {
                    if(temp.parentId == majorList.huowuList[i].key){
                        const tt = majorList.huowuList[i]
                        for (let m = 0; m < tt.children.length; m++) {
                            const third = tt.children[m]
                            if(temp.id == third.id) {
                                majorList.huowuList[i].checked= true
                                third.checked = true
                                getResult = true
                                break
                            }
                            else continue
                        }
                        if(getResult) break
                    }else continue
                }
                if(getResult) continue

                for (let i = 0; i < majorList.fuwuList.length; i++) {
                    if(temp.parentId == majorList.fuwuList[i].key){
                        const tt = majorList.fuwuList[i]
                        for (let m = 0; m < tt.children.length; m++) {
                            const third = tt.children[m]
                            if(temp.id == third.id) {
                                majorList.fuwuList[i].checked= true
                                third.checked = true
                                getResult = true
                                break
                            }
                            else continue
                        }
                        if(getResult) break
                    }else continue
                }
            }
            }
        },


        /** 二级专业的全选与取消 */
        selectedParent(item){
            /** 执行取消操作时，需要校验： 从step-3跳转过来的数据 需要校验是否可取消 */
            if(item.checked && this.fromRouter == 'demo-3'){
                for (let j = 0; j < this.selectedMajor.length; j++) {
                    const tt = this.selectedMajor[j];
                    if(tt.old && tt.parentId ==  item.id){
                        this.$toast({
                            message: '该专业下的 【' + tt.name  + '】不可取消',
                            type: 'fail',
                            className: 'div7'
                        })
                        return false
                    }
                }
            }
            item.checked = !item.checked
            for (let i = 0; i < item.children.length; i++) {
                item.children[i].checked = item.checked;
                if(item.checked){ // 最终为选中
                    this.selectedMajor.push({
                        id: item.children[i].id,
                        parentId: item.key,
                        name: item.children[i].name,
                        fullName: item.name  + "-" + item.children[i].name
                    })
                }

            }
            if(!item.checked){ // 最终为取消
                for (let m = this.selectedMajor.length-1; m >= 0; m--) {
                    const mm = this.selectedMajor[m];
                    if(mm.parentId == item.id)
                        this.selectedMajor.splice(m,1)

                }
            }

            console.log(this.selectedMajor);
        },

        /** 三级专业的全选与取消 */
        selectedSon(parent,item){
            /** 执行取消操作时，需要校验： 从step-3跳转过来的数据 需要校验是否可取消 */
            if(item.checked && this.fromRouter == 'demo-3'){
                for (let j = 0; j < this.selectedMajor.length; j++) {
                    const tt = this.selectedMajor[j];
                    if(tt.old && tt.id ==  item.id){
                        this.$toast({
                            message: '该专业不可取消',
                            type: 'fail',
                            className: 'div7'
                        })
                        return false
                    }
                }
            }
            let othersSelcted = false
            item.checked = !item.checked
            if(item.checked) { // 选中
                parent.checked = true
                /** 将选中的数据放入数组 */
                this.selectedMajor.push({
                    id: item.id,
                    parentId: parent.key,
                    name: item.name,
                    fullName: parent.name  + "-" + item.name
                })

            }else { // 取消
                for (let i = 0; i < parent.children.length; i++) {
                    const el = parent.children[i];
                    if(el.checked){
                        othersSelcted = true
                    }
                }
                parent.checked = othersSelcted
                /** 将取消的数据移出数组 */
                for (let m = this.selectedMajor.length-1; m >= 0; m--) {
                    const mm = this.selectedMajor[m];
                    if(mm.id == item.id){
                        this.selectedMajor.splice(m,1)
                        break
                    }

                }
            }

            console.log(this.selectedMajor);

        },

        /** 保存所选专业 */
        doSave(){
            // // 清空原所选专业
            // this.selectedMajor = []
            // for (let i = 0; i < majorList.gongchengList.length; i++) {
            //     const el = majorList.gongchengList[i];
            //     if(el.checked){
            //         for (let i = 0; i < el.children.length; i++) {
            //             const tt = el.children[i];
            //             if(tt.checked){
            //                 this.selectedMajor.push({
            //                     id: tt.id,
            //                     parentId: el.key,
            //                     name: tt.name,
            //                     fullName: el.name  + "-" + tt.name
            //                 })
            //             }
            //             else continue
            //         }
            //     } else continue
            // }

            // for (let i = 0; i < majorList.huowuList.length; i++) {
            //     const el = majorList.huowuList[i];
            //     if(el.checked){
            //         for (let i = 0; i < el.children.length; i++) {
            //             const tt = el.children[i];
            //             if(tt.checked){
            //                 this.selectedMajor.push({
            //                     id: tt.id,
            //                     parentId: el.key,
            //                     name: tt.name,
            //                     fullName: el.name  + "-" + tt.name
            //                 })
            //             }
            //             else continue
            //         }
            //     } else continue
            // }

            // for (let i = 0; i < majorList.fuwuList.length; i++) {
            //     const el = majorList.fuwuList[i];
            //     if(el.checked){
            //         for (let i = 0; i < el.children.length; i++) {
            //             const tt = el.children[i];
            //             if(tt.checked){
            //                 this.selectedMajor.push({
            //                     id: tt.id,
            //                     parentId: el.key,
            //                     name: tt.name,
            //                     fullName: el.name  + "-" + tt.name
            //                 })
            //             }
            //             else continue
            //         }
            //     } else continue
            // }
            /** 保存： 更新缓存 **/
            this.$ls.set('major-demo-list', this.selectedMajor, 2 * 60 * 60 * 1000)
            this.$router.push('/' + this.fromRouter)
        }
    }
}
</script>

<style scoped>
    li {
        margin-top: 0.2rem;
    }
</style>
