export default {
    "gongchengList": [
        {
            "id": "126a91fbcc94477885c68207d05a2e81",
            "key": "126a91fbcc94477885c68207d05a2e81",
            "name": "规划",
            "click": false,
            "checked": false,
            "majorName": "规划",
            "majorAlias": "",
            "majorCode": "A01",
            "parentId": "",
            "parentIdDictText": "",
            "firstClassId": "1342400392671019010",
            "firstClassIdDictText": "工程类",
            "enabledStatus": "0",
            "seqNum": 10,
            "delFlag": "0",
            "createTime": "2021-01-27",
            "children": [
                {
                    "id": "eceb3d8596fd47089e2b00c0395709f7",
                    "key": "eceb3d8596fd47089e2b00c0395709f7",
                    "name": "国民经济和社会发展规划",
                    "click": false,
                    "checked": false,
                    "majorName": "国民经济和社会发展规划",
                    "majorAlias": "",
                    "majorCode": "A0101",
                    "parentId": "126a91fbcc94477885c68207d05a2e81",
                    "parentIdDictText": "规划",
                    "firstClassId": "1342400392671019010",
                    "firstClassIdDictText": "工程类",
                    "enabledStatus": "0",
                    "seqNum": 20,
                    "delFlag": "0",
                    "createTime": "2021-01-27",
                    "children": null,
                    "leaf": true
                },
                {
                    "id": "58f316780c444c6bb40d07ab7d8db72b",
                    "key": "58f316780c444c6bb40d07ab7d8db72b",
                    "name": "城乡规划",
                    "click": false,
                    "checked": false,
                    "majorName": "城乡规划",
                    "majorAlias": "",
                    "majorCode": "A0102",
                    "parentId": "126a91fbcc94477885c68207d05a2e81",
                    "parentIdDictText": "规划",
                    "firstClassId": "1342400392671019010",
                    "firstClassIdDictText": "工程类",
                    "enabledStatus": "0",
                    "seqNum": 30,
                    "delFlag": "0",
                    "createTime": "2021-01-27",
                    "children": null,
                    "leaf": true
                },
                {
                    "id": "360620c308db451a942c8632d9e71031",
                    "key": "360620c308db451a942c8632d9e71031",
                    "name": "土地利用总体规划",
                    "click": false,
                    "checked": false,
                    "majorName": "土地利用总体规划",
                    "majorAlias": "",
                    "majorCode": "A0103",
                    "parentId": "126a91fbcc94477885c68207d05a2e81",
                    "parentIdDictText": "规划",
                    "firstClassId": "1342400392671019010",
                    "firstClassIdDictText": "工程类",
                    "enabledStatus": "0",
                    "seqNum": 40,
                    "delFlag": "0",
                    "createTime": "2021-01-27",
                    "children": null,
                    "leaf": true
                }
            ],
            "leaf": false
        },
        {
            "id": "78918f3b8bb24a95ad3d86df784a9533",
            "key": "78918f3b8bb24a95ad3d86df784a9533",
            "name": "投资策划与决策",
            "click": false,
            "checked": false,
            "majorName": "投资策划与决策",
            "majorAlias": "",
            "majorCode": "A02",
            "parentId": "",
            "parentIdDictText": "",
            "firstClassId": "1342400392671019010",
            "firstClassIdDictText": "工程类",
            "enabledStatus": "0",
            "seqNum": 50,
            "delFlag": "0",
            "createTime": "2021-01-27",
            "children": [
                {
                    "id": "1c9825c9b972459c86be853509a7cbdf",
                    "key": "1c9825c9b972459c86be853509a7cbdf",
                    "name": "项目建议、可行性研究、评估及后评价",
                    "click": false,
                    "checked": false,
                    "majorName": "项目建议、可行性研究、评估及后评价",
                    "majorAlias": "",
                    "majorCode": "A0201",
                    "parentId": "78918f3b8bb24a95ad3d86df784a9533",
                    "parentIdDictText": "投资策划与决策",
                    "firstClassId": "1342400392671019010",
                    "firstClassIdDictText": "工程类",
                    "enabledStatus": "0",
                    "seqNum": 60,
                    "delFlag": "0",
                    "createTime": "2021-01-27",
                    "children": null,
                    "leaf": true
                }
            ],
            "leaf": false
        }
    ],
    "huowuList": [
        {
            "id": "da998f757e8946e39df467a118d4302f",
            "key": "da998f757e8946e39df467a118d4302f",
            "name": "机械、设备类",
            "click": false,
            "checked": false,
            "majorName": "机械、设备类",
            "majorAlias": "",
            "majorCode": "B01",
            "parentId": "",
            "parentIdDictText": "",
            "firstClassId": "1342400734167056386",
            "firstClassIdDictText": "货物类",
            "enabledStatus": "0",
            "seqNum": 1120,
            "delFlag": "0",
            "createTime": "2021-01-27",
            "children": [
                {
                    "id": "9d4acedf76f742fd84d5cee8e09a1c56",
                    "key": "9d4acedf76f742fd84d5cee8e09a1c56",
                    "name": "建筑机械",
                    "click": false,
                    "checked": false,
                    "majorName": "建筑机械",
                    "majorAlias": "",
                    "majorCode": "B0101",
                    "parentId": "da998f757e8946e39df467a118d4302f",
                    "parentIdDictText": "机械、设备类",
                    "firstClassId": "1342400734167056386",
                    "firstClassIdDictText": "货物类",
                    "enabledStatus": "0",
                    "seqNum": 1130,
                    "delFlag": "0",
                    "createTime": "2021-01-27",
                    "children": null,
                    "leaf": true
                },
                {
                    "id": "d65bc2dd9c82454b94581bf9dd0d1966",
                    "key": "d65bc2dd9c82454b94581bf9dd0d1966",
                    "name": "城市轨道工程设备",
                    "click": false,
                    "checked": false,
                    "majorName": "城市轨道工程设备",
                    "majorAlias": "",
                    "majorCode": "B0102",
                    "parentId": "da998f757e8946e39df467a118d4302f",
                    "parentIdDictText": "机械、设备类",
                    "firstClassId": "1342400734167056386",
                    "firstClassIdDictText": "货物类",
                    "enabledStatus": "0",
                    "seqNum": 1140,
                    "delFlag": "0",
                    "createTime": "2021-01-27",
                    "children": null,
                    "leaf": true
                },
                {
                    "id": "479dd1d210c0449691894c19858756e9",
                    "key": "479dd1d210c0449691894c19858756e9",
                    "name": "民航设备",
                    "click": false,
                    "checked": false,
                    "majorName": "民航设备",
                    "majorAlias": "",
                    "majorCode": "B0103",
                    "parentId": "da998f757e8946e39df467a118d4302f",
                    "parentIdDictText": "机械、设备类",
                    "firstClassId": "1342400734167056386",
                    "firstClassIdDictText": "货物类",
                    "enabledStatus": "0",
                    "seqNum": 1150,
                    "delFlag": "0",
                    "createTime": "2021-01-27",
                    "children": null,
                    "leaf": true
                },
                // {
                //     "id": "48907e9bd3e44c3d959a3ed42a05adf0",
                //     "key": "48907e9bd3e44c3d959a3ed42a05adf0",
                //     "name": "矿山机械",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "矿山机械",
                //     "majorAlias": "",
                //     "majorCode": "B0104",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1160,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "be1cbe5e4b4d4eca989a1448a4a2dfc7",
                //     "key": "be1cbe5e4b4d4eca989a1448a4a2dfc7",
                //     "name": "石油、天然气设备",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "石油、天然气设备",
                //     "majorAlias": "",
                //     "majorCode": "B0105",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1170,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "db6d2ab79afd44b3bd21010849eaac3f",
                //     "key": "db6d2ab79afd44b3bd21010849eaac3f",
                //     "name": "冶金机械",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "冶金机械",
                //     "majorAlias": "",
                //     "majorCode": "B0106",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1180,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "4ea29242157941dfad36da2bd04ac225",
                //     "key": "4ea29242157941dfad36da2bd04ac225",
                //     "name": "化工机械",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "化工机械",
                //     "majorAlias": "",
                //     "majorCode": "B0107",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1190,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "2a0d50cc970a48f9b806c14d4352849d",
                //     "key": "2a0d50cc970a48f9b806c14d4352849d",
                //     "name": "煤化工设备",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "煤化工设备",
                //     "majorAlias": "",
                //     "majorCode": "B0108",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1200,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "33dd0f04fcfc4e7a9f26912ab25e92dd",
                //     "key": "33dd0f04fcfc4e7a9f26912ab25e92dd",
                //     "name": "轻工机械",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "轻工机械",
                //     "majorAlias": "",
                //     "majorCode": "B0109",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1210,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "0ec598b58c1148739b41342fbe5614c3",
                //     "key": "0ec598b58c1148739b41342fbe5614c3",
                //     "name": "农业机械",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "农业机械",
                //     "majorAlias": "",
                //     "majorCode": "B0110",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1220,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "796996902d894ccf8af22de714bc4f23",
                //     "key": "796996902d894ccf8af22de714bc4f23",
                //     "name": "林业机械",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "林业机械",
                //     "majorAlias": "",
                //     "majorCode": "B0111",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1230,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "be13e25594d64fe39bbd3f89b5b2a81a",
                //     "key": "be13e25594d64fe39bbd3f89b5b2a81a",
                //     "name": "电子元器件及专用设备",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "电子元器件及专用设备",
                //     "majorAlias": "",
                //     "majorCode": "B0112",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1240,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "50d0d06802794c7fb32437ce737f73de",
                //     "key": "50d0d06802794c7fb32437ce737f73de",
                //     "name": "通信设备",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "通信设备",
                //     "majorAlias": "",
                //     "majorCode": "B0113",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1250,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "28eff3b57ba74887b8c8af08b4eec3d2",
                //     "key": "28eff3b57ba74887b8c8af08b4eec3d2",
                //     "name": "广播、影视、舞台设备",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "广播、影视、舞台设备",
                //     "majorAlias": "",
                //     "majorCode": "B0114",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1260,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "8be2ac798dc34220840947d246e3ed35",
                //     "key": "8be2ac798dc34220840947d246e3ed35",
                //     "name": "海洋设备",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "海洋设备",
                //     "majorAlias": "",
                //     "majorCode": "B0115",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1270,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "7b1587075ebc4e94aac15956621df3e6",
                //     "key": "7b1587075ebc4e94aac15956621df3e6",
                //     "name": "金属加工机械",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "金属加工机械",
                //     "majorAlias": "",
                //     "majorCode": "B0116",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1280,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "04b8230a43d94a63afef05f19a64cf03",
                //     "key": "04b8230a43d94a63afef05f19a64cf03",
                //     "name": "锅炉",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "锅炉",
                //     "majorAlias": "",
                //     "majorCode": "B0117",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1290,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "9de39df8f6b641999e4f5e6f76f8c393",
                //     "key": "9de39df8f6b641999e4f5e6f76f8c393",
                //     "name": "造纸机械",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "造纸机械",
                //     "majorAlias": "",
                //     "majorCode": "B0118",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1300,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "4f7500bc6b9744579df047cbdf0da376",
                //     "key": "4f7500bc6b9744579df047cbdf0da376",
                //     "name": "起重运输机械",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "起重运输机械",
                //     "majorAlias": "",
                //     "majorCode": "B0119",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1310,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "e3a94bbd26d34d24bb88309f040745a8",
                //     "key": "e3a94bbd26d34d24bb88309f040745a8",
                //     "name": "纺织机械",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "纺织机械",
                //     "majorAlias": "",
                //     "majorCode": "B0120",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1320,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "d5bb6a01628545b18bfc584a7bd29873",
                //     "key": "d5bb6a01628545b18bfc584a7bd29873",
                //     "name": "包装设备",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "包装设备",
                //     "majorAlias": "",
                //     "majorCode": "B0121",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1330,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "10c6fbbee6f14c8c93c7312a65f5da6a",
                //     "key": "10c6fbbee6f14c8c93c7312a65f5da6a",
                //     "name": "生物制造设备",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "生物制造设备",
                //     "majorAlias": "",
                //     "majorCode": "B0122",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1340,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "b3784b32ad1e433aac99432d116c23e8",
                //     "key": "b3784b32ad1e433aac99432d116c23e8",
                //     "name": "粮油食品加工机械",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "粮油食品加工机械",
                //     "majorAlias": "",
                //     "majorCode": "B0123",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1350,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "af93a5145428444c88b8a1167207c392",
                //     "key": "af93a5145428444c88b8a1167207c392",
                //     "name": "非金属矿物制品工业专用设备",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "非金属矿物制品工业专用设备",
                //     "majorAlias": "",
                //     "majorCode": "B0124",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1360,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "057c5fc92bb640448863674e05c3fd50",
                //     "key": "057c5fc92bb640448863674e05c3fd50",
                //     "name": "车辆",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "车辆",
                //     "majorAlias": "",
                //     "majorCode": "B0125",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1370,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "ce6ff2c08cb64a849065810a01b4e407",
                //     "key": "ce6ff2c08cb64a849065810a01b4e407",
                //     "name": "船舶设备",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "船舶设备",
                //     "majorAlias": "",
                //     "majorCode": "B0126",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1380,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "027fdcca821943848a3860c6e7b44641",
                //     "key": "027fdcca821943848a3860c6e7b44641",
                //     "name": "航空航天设备",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "航空航天设备",
                //     "majorAlias": "",
                //     "majorCode": "B0127",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1390,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "b0b51a0a83e44a0e949b3eaeec129334",
                //     "key": "b0b51a0a83e44a0e949b3eaeec129334",
                //     "name": "兵器装备",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "兵器装备",
                //     "majorAlias": "",
                //     "majorCode": "B0128",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1400,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "93f73cf6f611410d9bdc9a463a7c11c6",
                //     "key": "93f73cf6f611410d9bdc9a463a7c11c6",
                //     "name": "核设备",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "核设备",
                //     "majorAlias": "",
                //     "majorCode": "B0129",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1410,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "389927c906314df483d738bf4c212732",
                //     "key": "389927c906314df483d738bf4c212732",
                //     "name": "电机",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "电机",
                //     "majorAlias": "",
                //     "majorCode": "B0130",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1420,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "53f6e58fb0ec4cd6a11169ce8b299868",
                //     "key": "53f6e58fb0ec4cd6a11169ce8b299868",
                //     "name": "输变电设备、设施",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "输变电设备、设施",
                //     "majorAlias": "",
                //     "majorCode": "B0131",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1430,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "91b52be0b12f4a8d8554ffbc6aa1deec",
                //     "key": "91b52be0b12f4a8d8554ffbc6aa1deec",
                //     "name": "计算机及网络设备",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "计算机及网络设备",
                //     "majorAlias": "",
                //     "majorCode": "B0132",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1440,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "2248f6e1eb154a3dad38597154b72ac9",
                //     "key": "2248f6e1eb154a3dad38597154b72ac9",
                //     "name": "计算机系统软件",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "计算机系统软件",
                //     "majorAlias": "",
                //     "majorCode": "B0133",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1450,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "fe381e9bd95c4b6ebc47dceb6a127c60",
                //     "key": "fe381e9bd95c4b6ebc47dceb6a127c60",
                //     "name": "环保设备",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "环保设备",
                //     "majorAlias": "",
                //     "majorCode": "B0134",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1460,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "889433c00c5e4ddd8bdbdb9abc6bce92",
                //     "key": "889433c00c5e4ddd8bdbdb9abc6bce92",
                //     "name": "气象设备",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "气象设备",
                //     "majorAlias": "",
                //     "majorCode": "B0135",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1470,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "f4054b898b9d497e99e635ca112f1a4f",
                //     "key": "f4054b898b9d497e99e635ca112f1a4f",
                //     "name": "交通信号专用设备",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "交通信号专用设备",
                //     "majorAlias": "",
                //     "majorCode": "B0136",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1480,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "459af3b99a3c460c9fb0d22a5398c671",
                //     "key": "459af3b99a3c460c9fb0d22a5398c671",
                //     "name": "地震监测设备",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "地震监测设备",
                //     "majorAlias": "",
                //     "majorCode": "B0137",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1490,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "5459b1e2e3d042889878a7dc3a589c10",
                //     "key": "5459b1e2e3d042889878a7dc3a589c10",
                //     "name": "节能设备",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "节能设备",
                //     "majorAlias": "",
                //     "majorCode": "B0138",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1500,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "ff7bf37fbb5441068cf96826aedfe346",
                //     "key": "ff7bf37fbb5441068cf96826aedfe346",
                //     "name": "科学、教育设备",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "科学、教育设备",
                //     "majorAlias": "",
                //     "majorCode": "B0139",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1510,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "68ed05e03b474ef79332085e31e590b0",
                //     "key": "68ed05e03b474ef79332085e31e590b0",
                //     "name": "安全技术防范设备",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "安全技术防范设备",
                //     "majorAlias": "",
                //     "majorCode": "B0140",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1520,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // },
                // {
                //     "id": "87090402563a4e14a0265fd465eb66d5",
                //     "key": "87090402563a4e14a0265fd465eb66d5",
                //     "name": "其他专用机械",
                //     "click": false,
                //     "checked": false,
                //     "majorName": "其他专用机械",
                //     "majorAlias": "",
                //     "majorCode": "B0141",
                //     "parentId": "da998f757e8946e39df467a118d4302f",
                //     "parentIdDictText": "机械、设备类",
                //     "firstClassId": "1342400734167056386",
                //     "firstClassIdDictText": "货物类",
                //     "enabledStatus": "0",
                //     "seqNum": 1530,
                //     "delFlag": "0",
                //     "createTime": "2021-01-27",
                //     "children": null,
                //     "leaf": true
                // }
            ],
            "leaf": false
        },
        {
            "id": "b411620dbf8b4c0080a7430907df426e",
            "key": "b411620dbf8b4c0080a7430907df426e",
            "name": "医疗器械",
            "click": false,
            "checked": false,
            "majorName": "医疗器械",
            "majorAlias": "",
            "majorCode": "B02",
            "parentId": "",
            "parentIdDictText": "",
            "firstClassId": "1342400734167056386",
            "firstClassIdDictText": "货物类",
            "enabledStatus": "0",
            "seqNum": 1540,
            "delFlag": "0",
            "createTime": "2021-01-27",
            "children": [
                {
                    "id": "d4213686f9384f37a3343820bd7506e0",
                    "key": "d4213686f9384f37a3343820bd7506e0",
                    "name": "医疗器械",
                    "click": false,
                    "checked": false,
                    "majorName": "医疗器械",
                    "majorAlias": "",
                    "majorCode": "B0201",
                    "parentId": "b411620dbf8b4c0080a7430907df426e",
                    "parentIdDictText": "医疗器械",
                    "firstClassId": "1342400734167056386",
                    "firstClassIdDictText": "货物类",
                    "enabledStatus": "0",
                    "seqNum": 1550,
                    "delFlag": "0",
                    "createTime": "2021-01-27",
                    "children": null,
                    "leaf": true
                }
            ],
            "leaf": false
        }
    ],
    "fuwuList": [
        {
            "id": "f96daa28654b45b78fd3793c5894512e",
            "key": "f96daa28654b45b78fd3793c5894512e",
            "name": "勘查与调查",
            "click": false,
            "checked": false,
            "majorName": "勘查与调查",
            "majorAlias": "",
            "majorCode": "C01",
            "parentId": "",
            "parentIdDictText": "",
            "firstClassId": "1342401172429881346",
            "firstClassIdDictText": "服务类",
            "enabledStatus": "0",
            "seqNum": 2040,
            "delFlag": "0",
            "createTime": "2021-01-27",
            "children": [
                {
                    "id": "f80c1b246bea4bd7988193318fab9c29",
                    "key": "f80c1b246bea4bd7988193318fab9c29",
                    "name": "地球勘查",
                    "click": false,
                    "checked": false,
                    "majorName": "地球勘查",
                    "majorAlias": "",
                    "majorCode": "C0101",
                    "parentId": "f96daa28654b45b78fd3793c5894512e",
                    "parentIdDictText": "勘查与调查",
                    "firstClassId": "1342401172429881346",
                    "firstClassIdDictText": "服务类",
                    "enabledStatus": "0",
                    "seqNum": 2050,
                    "delFlag": "0",
                    "createTime": "2021-01-27",
                    "children": null,
                    "leaf": true
                },
                {
                    "id": "ea3048a7dee84786b1eec8c72f0353b8",
                    "key": "ea3048a7dee84786b1eec8c72f0353b8",
                    "name": "地质调查",
                    "click": false,
                    "checked": false,
                    "majorName": "地质调查",
                    "majorAlias": "",
                    "majorCode": "C0102",
                    "parentId": "f96daa28654b45b78fd3793c5894512e",
                    "parentIdDictText": "勘查与调查",
                    "firstClassId": "1342401172429881346",
                    "firstClassIdDictText": "服务类",
                    "enabledStatus": "0",
                    "seqNum": 2060,
                    "delFlag": "0",
                    "createTime": "2021-01-27",
                    "children": null,
                    "leaf": true
                },
                {
                    "id": "81472808bf62433e9151ab5f02134bc5",
                    "key": "81472808bf62433e9151ab5f02134bc5",
                    "name": "生态与资源调查",
                    "click": false,
                    "checked": false,
                    "majorName": "生态与资源调查",
                    "majorAlias": "",
                    "majorCode": "C0103",
                    "parentId": "f96daa28654b45b78fd3793c5894512e",
                    "parentIdDictText": "勘查与调查",
                    "firstClassId": "1342401172429881346",
                    "firstClassIdDictText": "服务类",
                    "enabledStatus": "0",
                    "seqNum": 2070,
                    "delFlag": "0",
                    "createTime": "2021-01-27",
                    "children": null,
                    "leaf": true
                },
                {
                    "id": "fc43c0d27ee44e1391085fb555cb5f68",
                    "key": "fc43c0d27ee44e1391085fb555cb5f68",
                    "name": "矿产勘查",
                    "click": false,
                    "checked": false,
                    "majorName": "矿产勘查",
                    "majorAlias": "",
                    "majorCode": "C0104",
                    "parentId": "f96daa28654b45b78fd3793c5894512e",
                    "parentIdDictText": "勘查与调查",
                    "firstClassId": "1342401172429881346",
                    "firstClassIdDictText": "服务类",
                    "enabledStatus": "0",
                    "seqNum": 2080,
                    "delFlag": "0",
                    "createTime": "2021-01-27",
                    "children": null,
                    "leaf": true
                },
                {
                    "id": "3bfc20e391d04e6783669f7538dfc30c",
                    "key": "3bfc20e391d04e6783669f7538dfc30c",
                    "name": "水文监测与调查",
                    "click": false,
                    "checked": false,
                    "majorName": "水文监测与调查",
                    "majorAlias": "",
                    "majorCode": "C0105",
                    "parentId": "f96daa28654b45b78fd3793c5894512e",
                    "parentIdDictText": "勘查与调查",
                    "firstClassId": "1342401172429881346",
                    "firstClassIdDictText": "服务类",
                    "enabledStatus": "0",
                    "seqNum": 2090,
                    "delFlag": "0",
                    "createTime": "2021-01-27",
                    "children": null,
                    "leaf": true
                },
                {
                    "id": "8c076cb737a640d6a13b41d3394cedaf",
                    "key": "8c076cb737a640d6a13b41d3394cedaf",
                    "name": "海洋调查与监测",
                    "click": false,
                    "checked": false,
                    "majorName": "海洋调查与监测",
                    "majorAlias": "",
                    "majorCode": "C0106",
                    "parentId": "f96daa28654b45b78fd3793c5894512e",
                    "parentIdDictText": "勘查与调查",
                    "firstClassId": "1342401172429881346",
                    "firstClassIdDictText": "服务类",
                    "enabledStatus": "0",
                    "seqNum": 2100,
                    "delFlag": "0",
                    "createTime": "2021-01-27",
                    "children": null,
                    "leaf": true
                }
            ],
            "leaf": false
        },
        {
            "id": "ab0ec98ad9fa4fa78a3f8ac935ea2340",
            "key": "ab0ec98ad9fa4fa78a3f8ac935ea2340",
            "name": "公共咨询",
            "click": false,
            "checked": false,
            "majorName": "公共咨询",
            "majorAlias": "",
            "majorCode": "C02",
            "parentId": "",
            "parentIdDictText": "",
            "firstClassId": "1342401172429881346",
            "firstClassIdDictText": "服务类",
            "enabledStatus": "0",
            "seqNum": 2110,
            "delFlag": "0",
            "createTime": "2021-01-27",
            "children": [
                {
                    "id": "93fb8f726c5b41a2bfba0019705054b2",
                    "key": "93fb8f726c5b41a2bfba0019705054b2",
                    "name": "咨询服务",
                    "click": false,
                    "checked": false,
                    "majorName": "咨询服务",
                    "majorAlias": "",
                    "majorCode": "C0201",
                    "parentId": "ab0ec98ad9fa4fa78a3f8ac935ea2340",
                    "parentIdDictText": "公共咨询",
                    "firstClassId": "1342401172429881346",
                    "firstClassIdDictText": "服务类",
                    "enabledStatus": "0",
                    "seqNum": 2120,
                    "delFlag": "0",
                    "createTime": "2021-01-27",
                    "children": null,
                    "leaf": true
                }
            ],
            "leaf": false
        }
    ],
}